<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>修改密码</span>
      </div>
      <div class="bg" align="center">
<!--        <el-image :src="login_logo"></el-image>-->
      </div>
      <div>
        <el-form :inline="false" class="form" style="text-align: left;" label-width="100px">
          <el-form-item label="原始密码">
            <el-input show-password v-model="item.oldPassword" placeholder="输入原始密码" size="small"></el-input>
          </el-form-item>

          <el-form-item label="新密码">
            <el-input show-password v-model="item.newPassword" placeholder="输入新密码" size="small"></el-input>
          </el-form-item>

          <el-form-item label="重复新密码">
            <el-input show-password v-model="item.newPasswordTwo" placeholder="输入新密码" size="small"></el-input>
          </el-form-item>

          <el-form-item style="text-align:left;">
            <el-button type="button" class="el-button--primary" @click="submit" style="margin-right:20px;">确定
            </el-button>
            <router-link :to="{path: `/admin/home_page/index`}">
              <el-button type="button" class="el-button--default" @click="">取消</el-button>
            </router-link>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "change.vue",
  data() {
    return {
      fullscreenLoading: false,
      item: {
        user_id: null,
        oldPassword: null,
        newPassword: null,
        newPasswordTwo: null,
      },
      passwordType: 'password',
      districtList: []
    }
  },
  mounted() {
    this.item.user_id = localStorage.getItem('admin_id')
  },
  methods: {
    submit() {
      let _this = this
      if (this.item.oldPassword === null || this.item.oldPassword === "") {
        _this.$message.error('请输入原始密码')
        return false
      }
      if (this.item.newPassword === null || this.item.newPassword === "") {
        _this.$message.error('请输入新密码')
        return false
      }
      if (this.item.newPassword !== this.item.newPasswordTwo) {
        _this.$message.error('新密码输入不一致')
        return false
      }
      if (this.item.newPassword.length < 6) {
        _this.$message.error('密码至少为6位')
        return false
      }
      _this.fullscreenLoading = true
      let params = {
        id: _this.item.user_id,
        old_password: _this.$md5(_this.item.oldPassword),
        new_password: _this.$md5(_this.item.newPassword)
      }
      adminHttp.post('/backend/admin/resetPassword', params).then(result => {
        if (result.code == 200) {
          _this.$message.success(result.message || '密码修改成功')
          _this.fullscreenLoading = false
          this.$store.commit('logout')
          this.$router.push({path: '/admin/login'})
        } else {
          _this.$message.error(result.message || '密码修改失败')
          _this.fullscreenLoading = false
        }
      }).catch(error => {
        this.$message.error('密码修改失败，请稍后再试')
      }).finally(() => {
        this.fullscreenLoading = false
      })
    },
  }
}
</script>

<style lang="scss">
.bg {
  padding-top: 10px;
  margin: 0 auto;
  width: 20%;
  max-width: 300px;
}
</style>